import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/silver';

const onReady = (fn) => {
  if (document.readyState != 'loading'){
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}

onReady(() => {
  if (document.getElementById('mail-content')) {
    tinymce.init({ selector: '#mail-content' });
  }

  if (document.getElementById('news-content')) {
    tinymce.init({ selector: '#news-content' });

    const { value } = document.getElementById('old-news-content');

    tinymce.activeEditor.setContent(value);
  }
});
